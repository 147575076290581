import React, { useEffect, useState } from 'react'

const Page = () => {

    const [lists, setLists] = useState([])

    const getLists = () => {

        const header = {
            "content-type": "application/json",
            accept: "application/json"
        }

        fetch('https://a1.adsai.tech/api/v1/retail-apk/', {
            method: "GET",
            headers: header
        }).then(response => {
            response.json().then(data => {
                if (data && data.length > 0) {
                    setLists(data)
                } else {
                    setLists([])
                }
            })
        })
        .catch(error => {
            setLists([])
        });
    }

    useEffect(() => {
        getLists()
    }, [])

    const download = (url) => {
        const a = document.createElement('a')
        a.href = url
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }

    return (
        <section className='pt-0'>
            <div className='text-center'>
                <img src={require('../../assets/img/logo.png').default} className="img-fluid" alt="logo" style={{ width: "200px" }} />
            </div>
            <div className='d-flex flex-column align-items-center justify-content-center button-lists'>
                {(lists && lists.length > 0) ? <>
                    {(lists.map((item) => (
                        <><button className='btn btn-outline-primary' onClick={() => download(item.file)}>{item.title}</button></>
                    )))}
                </> : <>
                    <p className='text-dark mt-5'>No Records Found!</p>
                </>}
            </div>
            <div className='bottom-footer'>
                <p className='text-dark'>Copyright @ 2024 with signage.adsai.tech. All rights reserved.</p>
            </div>
        </section>
    )
}

export default Page